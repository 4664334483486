<template lang="pug">
  tr(@click="$emit('select-shop', shop)")
    td.shop-code {{ shop.code }}
    td.shop-name {{ shop.name }}
    td.inventory-group {{ shop.inventory_group_name }}
    td.address {{ shop.address }}
    td.status {{ status }}
    td.updated-at {{ shop.last_updated }}
    td.actions.right
      AppIconButton.action-button(
        useAppIcon
        icon="trash"
        @click.stop="$emit('remove-shop', shop)"
      )
</template>

<script>
  export default {
    props: {
      shop: {
        type: Object,
        required: true
      }
    },

    components: {
      AppIconButton: () => import("@/components/elements/AppButton/WithIcon/Other")
    },

    computed: {
      status({ shop: { released } }) {
        return this.$t(`company_system.${released ? "release" : "private"}`)
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/mixins/common.sass"

  tr
    cursor: pointer

  td
    &.shops
      padding-left: 10px

    &.actions
      text-align: center

      .action-button
        +icon-button($default-purple)
</style>
